import { initFirebase } from "../firebase/firebaseApp";
import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { useEffect } from "react";
import google_accounts_logo from "../assets/icons/google_accounts.png";

export const AuthPage = () => {
  const app = initFirebase();
  const provider = new GoogleAuthProvider();
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);

  const signIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      // Only redirect if sign in was successful
      if (result.user) {
        window.location.assign("/payment");
      }
    } catch (error) {
      console.error("Sign in error:", error);
    }
  };

  // Handle the case when user is already signed in
  const handleButtonClick = () => {
    if (user) {
      window.location.assign("/payment");
    } else {
      signIn();
    }
  };

  if (loading) return null; // Minimal loading state

  return (
    <div className="upload-function-container">
      <div
        className="upload-function-box"
        style={{
          borderRadius: "20px",
          overflow: "hidden",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          height: "300px",
          width: "500px",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <button
          className="close-button"
          onClick={() => (window.location.href = "/")}
        >
          ×
        </button>
        <p className="text-overlay-upload">{"Sign in"}</p>
        <p
          className="text-instructions-upload"
          style={{
            marginTop: "80px",
            color: "black",
            textAlign: "center",
            padding: "0 40px",
          }}
        >
          {user
            ? "Click to continue to payment"
            : "Sign in with your Google Account to upload your selfies."}
        </p>
        <button
          onClick={handleButtonClick}
          style={{
            marginTop: "auto",
            marginBottom: "60px",
          }}
        >
          <div className="sign_in_button_layout">
            <img
              src={google_accounts_logo}
              alt="Google Accounts logo"
              className="google_account_icon"
            />
            <span>{user ? "Continue" : "Sign In"}</span>
          </div>
        </button>
      </div>
    </div>
  );
};

export default AuthPage;
