import React, { useState } from "react";
import { useStorage } from "../hooks/useStorage";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import { projectStorage } from "../firebase/config";
import { getAuth } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";

import { Route } from "react-router-dom";

export const Upload_Function = () => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const [downloadURL, setDownloadURL] = useState("");

  const getUploadParams = ({ meta, file }) => {
    meta.percent = 100;
    return {
      url: "",
      meta: {
        fileName: file.name,
        fileType: file.type,
      },
    };
  };

  const upload_all = ({ meta, file }) => {
    // Get the authenticated user's Firebase Storage reference
    const fileRef = ref(
      projectStorage,
      `image/${user.displayName}-${meta.name}`,
    );
    // Get the upload session URL
    const uploadURL = `https://firebasestorage.googleapis.com/v0/b/${fileRef.bucket}/o`;
    const uploadTask = uploadBytesResumable(fileRef, file);

    uploadTask.on("state_changed", (snapshot) => {
      switch (snapshot.state) {
        case "paused":
          console.log("Upload is paused");
          break;
        case "running":
          console.log("Upload is running");
          break;
      }
    });
  };

  const [text, setText] = useState("Upload 30 selfies!");
  const [extraText, setExtraText] = useState("");
  const [showDropzone, setShowDropzone] = useState(true);
  const [progressUpload, setProgressUpload] = useState(0);

  const handleSubmit = (files, allFiles) => {
    if (!user) {
      window.location.href = "/login";
      return;
    }

    setShowDropzone(false);

    setExtraText(
      <div>
        <p>Uploading...</p>
        {/* <div
          style={{
            width: `${progressUpload}%`,
            height: '20px',
            backgroundColor: '#4caf50'
          }}
        /> */}
      </div>,
    );

    setText("Upload is being processed");

    for (let i = 0; i < allFiles.length; i++) {
      const name = allFiles[i].file.name;
      const storageRef = ref(
        projectStorage,
        `image/${user.displayName}-${name}`,
      );
      const uploadTask = uploadBytesResumable(storageRef, allFiles[i].file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          setProgressUpload(progress); // to show progress upload

          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");

              if (progress < 100) {
                console.log(`Progress: ${progress}%`);
              } else {
                console.log("Upload is running");
                setText("Thank you for uploading!");
                setExtraText(
                  <div>
                    <p>
                      Your AI Portraits will be with you in 1-2 business days.
                    </p>
                    <p>You can close this window now.</p>
                  </div>,
                );
                setShowDropzone(false);
                break;
              }
          }
        },

        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            //url is download url of file
            setDownloadURL(url);
          });
        },
      );
    }

    console.log(files.map((f) => f.meta));
    allFiles.forEach((f) => f.remove());
  };

  const [isUpdatePreview, setIsUpdatePreview] = useState(false);
  const width = window.innerWidth > 901 ? 500 : 300;

  // called every time a file's `status` changes
  const handleChangeStatus = ({ meta, file }, status) => {
    setIsUpdatePreview(!isUpdatePreview);
    meta.status = "done";
  };

  return (
    <div className="upload-function-container">
      <div
        className="upload-function-box"
        style={{
          borderRadius: "20px",
          overflow: "hidden",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          height: "300px",
          width: "500px",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <button
          className="close-button"
          onClick={() => {
            auth.signOut();
            window.location.href = "/";
          }}
        >
          ×
        </button>
        <p className="text-overlay-upload">{text}</p>
        <p className="extra-text-uplod-succesful">{extraText}</p>
        {showDropzone && (
          <Dropzone
            getUploadParams={getUploadParams}
            onChangeStatus={handleChangeStatus}
            onSubmit={handleSubmit}
            accept="image/*"
            maxFiles={30}
            inputContent="Drag Files or Click to Browse"
            inputWithFilesContent={(files) => `${30 - files.length} more`}
            submitButtonDisabled={(files) => files.length < 30}
            styles={{
              dropzone: {
                width,
                height: 500,
                borderColor: "white",
                marginTop: "5%",
                borderRadius: "20px",
              },
              dropzoneActive: { borderColor: "white" },
              inputLabel: { top: "-40%" },
            }}
          />
        )}
      </div>
    </div>
  );
};
