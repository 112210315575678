import { useState, useEffect } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import logo from "../assets/img/logo.svg";
import new_logo from "../assets/img/new-logo-text.svg";

// import navIcon1 from '../assets/img/nav-icon1.svg';
// import navIcon2 from '../assets/img/nav-icon2.svg';
// import navIcon3 from '../assets/img/nav-icon3.svg';

export const NavBar = () => {
  const [activeLink, setActiveLink] = useState("home");
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
  };

  return (
    <Navbar expand="md" className={scrolled ? "scrolled" : ""}>
      <Container>
        <Navbar.Brand href="/">
          <a href="#home">
            <img
              src={new_logo}
              alt="Logo"
              style={{ width: "250%", height: "250%", marginTop: "-5px" }}
            />
          </a>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav">
          <span className="navbar-toggler-icon"></span>
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            {/* <Nav.Link href="#styles" className={activeLink === 'styles' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('styles')}>Styles</Nav.Link> */}
            <Nav.Link
              href="#samples"
              className={
                activeLink === "samples" ? "active navbar-link" : "navbar-link"
              }
              onClick={() => onUpdateActiveLink("samples")}
            >
              Samples
            </Nav.Link>
            {/* <Nav.Link href="#connect" className={activeLink === 'connect' ? 'active navbar-link' : 'navbar-link'} onClick={() => onUpdateActiveLink('connect')}>Support</Nav.Link> */}
          </Nav>
          <span className="navbar-text">
            <button
              // className="vvd"
              //  onClick={() => window.location.assign("/step_one")}
              // >
              onClick={() => window.location.assign("/step_one")}
              style={{
                textDecoration: "none",
                pointerEvents: "none", // Prevents interaction
                opacity: 0.5, // Makes the button appear grayed out
              }}
              disabled
            >
              <span>Get started!</span>
            </button>
          </span>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
