import { OnboardingProgressIndicator } from "./OnboardingProgressIndicator.js";

export const OnboardingProcess = () => {
    return (
        <div className="upload-function-container">
            <div
                className="upload-function-box"
                style={{
                    borderRadius: "20px",
                    overflow: "hidden",
                    boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
                    height: "300px",
                    width: "500px",
                    position: "relative",
                    backgroundColor: "white",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <button
                    className="close-button"
                    onClick={() => (window.location.href = "/")}
                >
                    ×
                </button>
                <p className="text-overlay-upload">{"Get Started!"}</p>
                <p
                    className="text-instructions-onboarding"
                    style={{
                        marginBottom: "20px",
                    }}
                >
                    {"Once you are ready, let's create your portraits!"}
                </p>
                <div
                    className="checklist-instructions-onboarding"
                    style={{
                        margin: "80px 40px 0 40px",
                        paddingRight: "120px",
                        position: "static",
                        flex: "1",
                    }}
                >
                    <label htmlFor="item1" style={checklistLabelStyle}>
                        <input
                            type="checkbox"
                            id="item1"
                            checked
                            onClick={(e) => e.preventDefault()}
                            style={checkboxStyle}
                        />
                        {" Log in with your Google Account"}
                    </label>
                    <label htmlFor="item2" style={checklistLabelStyle}>
                        <input
                            type="checkbox"
                            id="item2"
                            checked
                            onClick={(e) => e.preventDefault()}
                            style={checkboxStyle}
                        />
                        {" Pay £19.99"}
                    </label>
                    <label htmlFor="item3" style={checklistLabelStyle}>
                        <input
                            type="checkbox"
                            id="item3"
                            checked
                            onClick={(e) => e.preventDefault()}
                            style={checkboxStyle}
                        />
                        {" Upload 30 selfies"}
                    </label>
                    <label htmlFor="item4" style={checklistLabelStyle}>
                        <input
                            type="checkbox"
                            id="item4"
                            checked
                            onClick={(e) => e.preventDefault()}
                            style={checkboxStyle}
                        />
                        {" Receive 25 unique AI portraits in 48 hours"}
                    </label>
                </div>
                <button
                    onClick={() => window.location.assign("/login")}
                    style={{
                        marginTop: "auto",
                        marginBottom: "20px",
                    }}
                >
                    <div className="onboarding_button_layout">
                        <span>Ready!</span>
                    </div>
                </button>
                <div className="progress-indicator-container">
                    <OnboardingProgressIndicator currentStep={3} />
                </div>
            </div>
        </div>
    );
};

// Styles object for checklist labels
const checklistLabelStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    color: "black",
    fontSize: "16px",
    cursor: "default",
};

// Styles object for checkboxes
const checkboxStyle = {
    marginRight: "8px",
    width: "14px",
    height: "14px",
    cursor: "default",
};

export default OnboardingProcess;
