import { useEffect } from "react";
import stripe_logo from "../assets/icons/stripe_logo.png";

export const StripePaymentPage = () => {
  const stripeUrl = "https://buy.stripe.com/fZeg0o3fY1SQ3Kw7ss";

  const handleButtonClick = () => {
    window.open(stripeUrl, "_blank");
    window.close();
  };

  return (
    <div className="upload-function-container">
      <div
        className="upload-function-box"
        style={{
          borderRadius: "20px",
          overflow: "hidden",
          boxShadow: "0 4px 16px rgba(0, 0, 0, 0.1)",
          height: "300px",
          width: "500px",
          position: "relative",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <button
          className="close-button"
          onClick={() => (window.location.href = "/")}
        >
          ×
        </button>
        <p className="text-overlay-upload">{"Sign in"}</p>
        <p
          className="text-instructions-upload"
          style={{
            marginTop: "80px",
            color: "black",
            textAlign: "center",
            padding: "0 40px",
          }}
        >
          {"Visit Stripe to pay."}
        </p>
        <button
          onClick={handleButtonClick}
          style={{
            marginTop: "auto",
            marginBottom: "60px",
          }}
        >
          <div className="sign_in_button_layout">
            <img
              src={stripe_logo}
              alt="Stripe logo"
              className="stripe_logo_icon"
            />
          </div>
        </button>
      </div>
    </div>
  );
};

export default StripePaymentPage;
