import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect } from "react";

import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Skills } from "./components/Skills";
import { Projects } from "./components/Projects";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import { MailchimpForm } from "./components/MailchimpForm";
import { Upload_Function } from "./components/Uploader";
import { AuthPage } from "./components/AuthPage";
import { OnboardingPage } from "./components/OnboardingPage";
import { OnboardingPageChecklist } from "./components/OnboardingPageChecklist";
import { OnboardingProcess } from "./components/OnboardingProcess";
import { StripePaymentPage } from "./components/StripePaymentPage";

import { BrowserRouter as Router, useLocation } from "react-router-dom";

import Dashboard from "./components/Dashboard";

function Base_App() {
  const location = useLocation();

  useEffect(() => {
    window.history.replaceState({}, "", "/");
  }, [location.pathname]);

  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Projects />
      <MailchimpForm />
      <Footer />
      {location.pathname === "/dashboard" && <Dashboard />}
      {/* Keep existing route conditions */}
      {location.pathname === "/upload" && <Upload_Function />}
      {location.pathname === "/login" && <AuthPage />}
      {location.pathname === "/step_one" && <OnboardingPage />}
      {location.pathname === "/step_two" && <OnboardingPageChecklist />}
      {location.pathname === "/step_three" && <OnboardingProcess />}
      {location.pathname === "/payment" && <StripePaymentPage />}
    </div>
  );
}

function App() {
  return (
    <Router>
      <Base_App />
    </Router>
  );
}

export default App;
