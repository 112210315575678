import React, { useState, useEffect } from "react";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import { ref, uploadBytesResumable } from "@firebase/storage";
import { projectStorage } from "../firebase/config";
import Dropzone from "react-dropzone-uploader";

const Dashboard = () => {
  const auth = getAuth();
  const [user, loading] = useAuthState(auth);
  const [credits, setCredits] = useState(0);
  const [view, setView] = useState("login"); // login, dashboard, upload, generate
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadComplete, setUploadComplete] = useState(false);
  const [generationPrompt, setGenerationPrompt] = useState("");
  const [files, setFiles] = useState([]);

  const handleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      await signInWithPopup(auth, provider);
      setView("dashboard");
    } catch (error) {
      console.error("Sign in error:", error);
    }
  };

  const handleUpload = async (files) => {
    setUploadProgress(0);
    const uploadPromises = files.map((file) => {
      const storageRef = ref(projectStorage, `images/${user.uid}/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      return new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
          },
          reject,
          resolve,
        );
      });
    });

    try {
      await Promise.all(uploadPromises);
      setUploadComplete(true);
      setFiles(files);
    } catch (error) {
      console.error("Upload error:", error);
    }
  };

  const handleTrainAI = () => {
    if (credits < 50) {
      window.open("https://buy.stripe.com/fZeg0o3fY1SQ3Kw7ss", "_blank");
      return;
    }
    setCredits((prev) => prev - 50);
    // AI training logic here
  };

  const handleGeneratePortrait = () => {
    if (credits < 5) {
      window.open("https://buy.stripe.com/fZeg0o3fY1SQ3Kw7ss", "_blank");
      return;
    }
    setCredits((prev) => prev - 5);
    // Portrait generation logic here
  };

  return (
    <div className="upload-function-container">
      <div className="upload-function-box">
        {view === "login" && (
          <div className="flex flex-col items-center p-8">
            <h2 className="text-2xl mb-4">Sign In</h2>
            <button
              onClick={handleSignIn}
              className="bg-blue-500 text-white px-4 py-2 rounded"
            >
              Sign in with Google
            </button>
          </div>
        )}

        {view === "dashboard" && (
          <div className="flex flex-col p-8">
            <div className="flex justify-between mb-6">
              <span>Credits: {credits}</span>
              <button onClick={() => signOut(auth)} className="text-red-500">
                Log Out
              </button>
            </div>

            {!uploadComplete ? (
              <Dropzone
                onSubmit={handleUpload}
                accept="image/*"
                maxFiles={30}
                inputContent="Drop 30 selfies here"
              />
            ) : (
              <div className="space-y-4">
                <button
                  onClick={handleTrainAI}
                  className="w-full bg-green-500 text-white p-2 rounded"
                  disabled={credits < 50}
                >
                  Train AI on Selfies (50 credits)
                </button>

                <div className="space-y-2">
                  <input
                    type="text"
                    value={generationPrompt}
                    onChange={(e) => setGenerationPrompt(e.target.value)}
                    placeholder="Describe the portrait style..."
                    className="w-full p-2 border rounded"
                  />
                  <button
                    onClick={handleGeneratePortrait}
                    className="w-full bg-blue-500 text-white p-2 rounded"
                    disabled={credits < 5}
                  >
                    Generate Portrait (5 credits)
                  </button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
